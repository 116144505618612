@use 'base/variables' as v;
/* @mixin heading($color:blue) {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: $color;
    letter-spacing: 1px;
}
*/ 
 @mixin telefono {
    @media (min-width: v.$telefono){
        @content;
    }
 }
 @mixin tablet {
    @media (min-width: v.$tablet){
        @content;
    }
 }
 @mixin desktop {
    @media (min-width: v.$desktop){
        @content;
    }
 }
 @mixin desktopXL {
    @media (min-width: v.$desktopXL){
        @content;
    }
 } 
 @mixin grid($columnas,$espaciado) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
    padding: 5rem 0;
 }
 @mixin resetear-lista{
    margin: 0;
    padding: 0;
    list-style: none;
 }