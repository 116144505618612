@use 'base/variables' as v;
@use 'base/mixins' as m;

.footer {
    background-color: v.$azul;
    padding: 2rem;
    p {
        text-align: center;
        font-size: 1.5rem;

        color: v.$blanco;
    }
}