@use 'base/variables' as v;
@use 'base/mixins' as m;

.retos {
    padding: 5rem 0;;
    background-color: v.$blanco;

    h2{
        text-align: center;
        color: v.$rojo;
        font-weight: 700;
        margin-bottom: 0;
    }
    h3 {
        text-align: center;
        color: v.$grisOscuro;
        font-weight: 400; 
        font-size: 2.5rem;
    }
}
.reto-contenedor {
    .reto_p p {
        border-bottom: solid 2px v.$rojo;
        padding-bottom: 2rem;
        margin-bottom: 4rem;
    }

    @include m.tablet{
        @include m.grid(2,5rem);

        .reto_p p {
            border-left: solid 1px v.$rojo;
            padding-left: 1.5rem;
            border-bottom: none;
        }
    }
}
.reto {
    
   img {
        border: 1px solid rgba(0,0,0,0.095);
        padding: 5px !important;
        padding-bottom: 35px;
        box-shadow: 0 4px 3px rgb(0 0 0 / 8%);
        background: v.$blanco;
   }
}
.retos .dias {
    font-size: 2.6rem;
    background-color: v.$rojo;
    color: v.$blanco;
    display: inline-block;
    font-weight: 700;
    padding: 1rem;
    border-radius: 15px;
}