@use 'base/variables' as v;
@use 'base/mixins' as m;

/* Vamos hacerlo solo*/
.lineup {
    padding: 5rem 0;;
    background-color: v.$grisOscuro;
    h3,.dia{
        text-align: center;
        color: v.$blanco;
    }
    h3 {
        margin-bottom: 0;
    }
    .dia {
       text-transform: uppercase;
    }
}
.escenario-contenedor {
    .nombre-escenario {
        text-align: center
    }
    @include m.tablet{
        @include m.grid(2,5rem);
    }
}

.bg-amarillo {
    background-color: v.$amarillo;
    li:nth-child(odd) {
        background-color: darken(v.$amarillo, 15%);
    }
}
.bg-verde {
    background-color: v.$verde;
    li:nth-child(odd) {
        background-color: darken(v.$verde, 10%);
    }
}
.calendario {
    @include m.resetear-lista;
    li {
        padding: 1rem 1rem 1rem  5rem;
        font-weight: 400;
        background-image: url(../img/ok-2.svg);
        background-repeat: no-repeat;
        background-position: center left;
        background-position-x: 1rem;
        span {
            font-weight: 700;
        }
    }
}
.escenario {
    p {
        &::after {
            content: '';
            display:block;
            width: 20rem;
            height: 10rem;
            background-size: 15rem;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 2rem auto 0 auto;
        }
    }
}
.edm .nombre-escenario::after {
    background-image: url(../img/icono-rojo.svg);
}
