@use 'base/variables' as v;
@use 'base/mixins' as m;
.video {
    position: relative;
    max-height: 50rem;
    min-height: 40rem;
    overflow: hidden;
    .overlay {
        position:absolute;
        background: rgb(118,12,175);
        background: linear-gradient(90deg, rgba(36,34,34,0.7) 0%, rgba(114,6,6,0.62) 100%);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    .contenedor {
        width: 100%;
        max-width: 100%;
        margin-top: -25rem;
        @include m.tablet{
            max-width: 120rem; 
            margin-top: 0;   
        }
    }
    video {
        width: auto;
        display: block;
        @include m.tablet{ 
            width: 100%;

        }
        
    }
}
.contenido-video {
    z-index: 10;
    h2 {
        color: v.$blanco;
        font-size: 7rem;
        margin: 0px;
    }
    p {
        background-color: v.$negro;
        display: block;
        text-align: center;
        @include m.tablet{
            display: inline-block;
            text-align: left;
        }
        color: v.$blanco;
        padding: 1rem 2rem;
        font-weight: 700;
        font-size: 2.4rem;
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        @include m.tablet{
            position:relative;
        }
        span {
            color: v.$rojo;
        }
    }
    .contenido-video-logo{
        max-width :50rem;
        @include m.tablet{
            max-width :60rem
        }
    }
}
