@use 'base/variables' as v;
@use 'base/mixins' as m;
.sobre-festival {
    padding: 5rem 0;
        @include m.tablet{
            @include m.grid(2,5rem);
        }
    h2 {
        text-align: center;
        font-size: 2.4rem;
        line-height: 110%;
        margin: 2.8rem 0 1.68rem 0;
        font-family: v.$fuente_principal;
        small {
            color: v.$blanco;
            display: block;
            padding: 15px;
            background-color: v.$rojo;
            font-weight: bold;
            margin: 20px auto;
            width: 100%;
            max-width: 500px;
            text-align: center;
            border-radius: 10px;
        }
    }

}
.contenido-festival {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
    @include m.tablet{
        margin-top:0;
    }
    h2,h5,h6 {
        font-family: v.$fuente_arial;
        font-weight: lighter;
        margin: 0.7666666667rem 0 0.46rem 0;
    }
    h2 {
        margin-bottom: 2rem;
    }
    h5 {
        font-size: 1.64rem;
    }
    h6 {
        font-size: 1.5rem;
        line-height: 110%;
    }
    .fecha {
        color: v.$rosa;
        font-weight: 700;
    }
}
