@use 'base/variables' as v;
@use 'base/mixins' as m;

.suenios {
    padding: 5rem 0;;
    background-color: v.$verde;
    h3{
        text-align: center;
        color: v.$negro;
        font-weight: 400;
        font-size: 2.5rem;
        margin-bottom: 0;
    }
    .acepto {
        display: block;
        margin: 1rem auto;
        width: 100%;
        max-width: 60rem;
    }
}
.suenio-contenedor {
    @include m.tablet{
        @include m.grid(2,5rem);
    }
}
.suenio {
    h3 {
        color: v.$rojo;
    }
}
.suenio p:first-child {
    margin-top: 0;
}
.suenio p:last-child {
    margin-bottom: 0;
}