@use 'base/variables' as v;
@use 'base/mixins' as m;

.contenedor_curso {
    width: 100%;
    max-width: 140rem;
}
.cursos {
    background-color: v.$azul_claro;
    padding: 3rem 6rem;
    h3 {
        margin: 0;
    }
    .curso_titulo h2,.curso_titulo p {
        margin: 0;
        text-align: center;
    }
}
.curso_texto {
    @include m.tablet{
        @include m.grid(3,1rem);
    } 
}
.curso {
    padding: 3rem 0;
    h3 {
        text-align: center
    }

    @include m.grid(1,1rem);
    @include m.tablet{
        @include m.grid(3,1rem);
    }
    @include m.desktop{
        @include m.grid(5,2rem);
    }
    .curso_cell{
        display: flex;
        flex-direction: column;

        border-radius: 1rem 1rem 0 0;
        margin-bottom: 1.8rem;
        background-color: v.$azul_oscuro;
        .curso_img img {
            /* border-radius: 1rem 1rem 0 0;*/
        }
        h2 {
            font-size: 1.6rem;
            padding: 2rem 1.5rem;
            text-align: center;
            color: v.$azul_claro;
            margin: 0;
            min-height: 8rem;
        }
        .curso_autor {
            font-weight: 100;
            padding: 0 0 1.2rem 1.2rem;
            color: v.$azul_claro;
            font-style: italic;
            font-size: 1.6rem;
        }
        .curso_estrellas {
            display: flex;
            flex-direction: row;
        }
        .estrella {
            width: 12rem;
            height: 2.5rem;
            background-image: url(../img/estrellas.svg);
            background-repeat: no-repeat;
        }
        .curso_rango {
            color: v.$rojo;
            padding-left: 1rem;
            font-size: 2rem;
            font-weight: 700;
        }
        .curso_precio {
            color: v.$blanco;
            padding: 2rem 0;
            font-size: 2.2rem;
            font-weight: 700;
            text-align: center;
            
        }
    }
}
.taller h2 {
    margin: 0;
    padding: 1rem 2rem 0 2rem;
    font-size: 1.8rem;
}
.taller h3 {
    margin: 0;
    padding: 1rem 2rem 0 2rem;
    font-size: 1.7rem;
    font-weight: normal;
    font-style: italic;
}
.taller p {
    margin: 0;
    padding: 0.5rem 2rem 0;
    font-weight: 100;
    font-size: 1.8rem;
    line-height: 2.6rem;
    
}
.alumnos {
    padding: 1rem 2rem 0 2rem;
    display: flex;
    flex-direction: row;
}
.alumno {
    padding-left: 2rem;
    background-image: url(../img/icon-persona.svg);
    background-repeat: no-repeat;
    font-size: 1.8rem;
}
.megustan {
    padding-left: 2rem;
    background-image: url(../img/icon-manita.svg);
    background-repeat: no-repeat;
    font-size: 1.8rem;
    margin-left: 1.5rem;
}
.taller button {
    margin: 3rem auto;
    width: 80%;
    padding: 1.5rem 3rem;
    font-size: 2rem;
    font-weight: bold;
    background-color: v.$azul;
    border: none;
    border-radius: 1.5rem;
    color: v.$blanco;
    display: block;
}
/* Curso para mostrarlo */

.div_curso {
    display: flex;
    flex-direction: column;
    .curso_contenido {
        h1,h2 {
            margin: 0;
            padding: 0;
        }
        h1 {
            font-size: 2.7rem;
            padding-left: 2rem;
            padding-bottom: 1rem;
        }
        h2 {
            font-size: 2rem;
            font-weight: 100;
            padding-left: 2rem;
            padding-bottom: .5rem;
        }
        .bold {
            font-weight: bold;
            margin-top: 4rem;
        }
        .curso_autor {
            padding-left: 2rem;
        }
        .curso_estrellas {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .estrella {
            width: 12rem;
            height: 2.5rem;
            background-image: url(../img/estrellas.svg);
            background-repeat: no-repeat;
        }
        .curso_rango {
            color: v.$rojo;
            padding-left: 2rem;
            font-size: 2rem;
            font-weight: 700;
        }
        .alumnos {
            padding: 1rem;
        }
        .abrir {
            background-image: url(../img/icon-flecha-negro.svg);
        }
        .contenedor_video {
            width: 100%;
            max-width: 55rem;
            margin: 0 auto 4rem auto;
            h2 {
                margin: 0;
                padding: 0;
                font-size: 3rem;
                color: v.$azul_oscuro;
            }
            p {
                margin: 0;
                padding: 0;
                font-size: 2.2rem;
                color: v.$azul_oscuro;
                margin-bottom: 1rem;
                font-style: italic;
            }
        }
        .locutor {
            max-width: 450px;
            display: block;
            margin: 0px auto;
        }
    }
    @include m.tablet{
        flex-direction: row;
        justify-content: space-between;
        .curso_contenido {
            width: 70rem;
        }
        .curso_precio {
            width: 30rem;
            
            .curso_cell {
                width: 28rem;
                background-color: v.$azul_oscuro;
                margin: 0 auto;
                .alto {
                    height: 2.5rem;
                }
            }
            .curso_titulo {
                font-size: 1.8rem;
                text-align: center;
                padding-top: 1rem;
                color: v.$blanco;
                margin-bottom: 0;
                font-weight: 100;
            }
            .curso_autor {
                text-align: center;
                font-style: italic;
                color: v.$blanco;
            }
            .curso_precio {
                width: 80%;
                background-color: v.$azul_claro;
                margin: 1rem auto;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                padding: 0.5rem;
                color: v.$blanco;
            }
            .curso_terminos {
                color: v.$blanco;
                text-align: center;
                font-style: italic;
            }
        }
    }
    div {

    }
}
.aprenderas {
    display: grid;
    border: solid thin v.$gris;
    padding: 2rem;
    margin: 2rem;
    .punto {
        background-image: url(../img/icon-paloma.svg);
        background-repeat: no-repeat;
        padding-left: 45px;
        background-size: 35px;
        margin: 1rem;
        line-height: 1.4;
    }
    @include m.grid(1,2rem);
    @include m.tablet{
        @include m.grid(2,1rem);
        padding: 2rem;
        margin: 2rem;
    }

}
.incluye_div {
    display: grid;
    padding: 2rem;
    margin: 2rem;
    .incluye {
        padding-left: 45px;
        background-size: 35px;
        margin: 1rem;
        background-repeat: no-repeat;
        min-height: 6rem;
        border: solid thin;
        display: flex;
        align-items: center;
        background-position: center left;
        background-color: v.$azul_oscuro;
        color: v.$blanco;
        font-weight: 100;
        padding-right: 0.5rem;
        border-radius: 1rem;
        background-position: 0.5rem;
    }
    @include m.grid(1,2rem);
    @include m.tablet{
        @include m.grid(2,1rem);
        padding: 0rem;
        margin: 0rem;
    }
    .play {
        background-image: url(../img/icon-play-n.svg);
    }
    .movil {
        background-image: url(../img/icon-movil.svg);
    }
    .certificado {
        background-image: url(../img/icon-certificado.svg);
    }
}
.modulo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: v.$gris_claro;
    padding: 2rem 1rem;
    border: solid thin v.$gris;
    background-position: center left;
    background-position: 1rem;
    background-image: url(../img/icon-flecha.svg);
    background-repeat: no-repeat;
    padding-left: 45px;
    background-size: 20px;
    cursor: pointer;
    &:hover {
        background-color: v.$gris;
        color: v.$blanco;
        background-image: url(../img/icon-flecha-blanco.svg);
    }
}
.tema {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1rem;
    border-left: solid thin v.$gris;
    border-right: solid thin v.$gris;
    background-position: center left;
    background-position: 1rem;
    background-image: url(../img/icon-play-circulo.svg);
    background-repeat: no-repeat;
    padding-left: 45px;
    background-size: 33px;
    font-weight: 100;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        color: v.$grisOscuro;
        background-image: url(../img/icon-play-circulo-rojo.svg);
    }
}
.pdf {
    background-image: url(../img/icon-pdf.svg);
    &:hover {
        background-image: url(../img/icon-pdf-rojo.svg);
    }
}
.link {
    background-image: url(../img/icon-link.svg);
    &:hover {
        background-image: url(../img/icon-link-rojo.svg);
    }
}
.objetivo {
    background-image: url(../img/icon-objetivo.svg);
    cursor: auto;
    &:hover {
        background-image: url(../img/icon-objetivo-rojo.svg);
    }
}
.div_top{
    margin-top: 3rem;
}
.ocultar {
    display: none;
}
/* Video  */
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.embed-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.border_arriba {
    border-top: solid thin;
}