@use 'base/variables' as v;
@use 'base/mixins' as m;

.header {
  background-color: v.$gris;
  height: 7rem;
  padding-top: 0.4rem;
  @include m.tablet{
    height: 4rem;
    &.fijo {
        position: fixed;
        width: 100%;
        box-shadow: .1rem .2rem .3rem #333;
    }
  }
  h1 {
    color: v.$blanco;
    text-align: center;
    @include m.tablet{
        margin-bottom: 0;
    }
  }
  .contenido-header {
    padding: .2rem;
        @include m.tablet{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
  
}
.rojo {
    color: v.$rojo;
}
.dirigido {
    
    p {
        font-family: Montserrat,sans-serif;
        font-size: 2.4rem;
        text-align: center;
        width: 100%;
        max-width: 90rem;
        display: block;
        margin: 0 auto;
        padding: 1.2rem;
    }
    span {
        font-weight: bold;
    }
}
.navegacion-principal,.navegacion-principal_ {
    @include m.tablet{
        display:flex;
        gap: 2rem;
    }
    a {
        color: v.$negro;
        font-size: 1rem;
        display: block;
        text-align: center;
        margin-bottom: 1rem;
        .num_whatsapp {
            font-size: 1.6rem;
            font-weight: bold;
        }
        @include m.tablet{
            margin-bottom:0;
        }
        &:hover {
            color: v.$negro;
        }
    }
}
.logo img {
    display: inline;
    line-height: 1rem;
}
.barra {
    width: 3rem;
}
.nav_titulo {
    font-size: 1.2rem;
    font-family: v.$fuente_principal;

    font-size: 1.2rem;
    margin-top: -2.6rem;
    /*border: solid thin;*/
    display: block;
    padding-left: 3.5rem;

}
.inicio_ {
    background-color: v.$inicio;
    padding: 5rem 0;
    .inicio_div {
        @include m.tablet{
        display: flex;
        justify-content: space-between;
        align-items: center;
        }
        h2 {
            font-size: 3rem;
            text-align: center;
            margin: 0px;
        }
    }
    
}
.centrado {
    text-align: center;
}
.img_450 {
    width: 100%;
    max-width: 45rem;
}
.formulario_ h2 {
    font-size: 2.2rem;
    margin: 0;
    text-align: center;
    font-weight: 100;
}
.formulario_ .rojo {
    color: v.$rojo;
    font-weight: 400;
}
#form_div_nuevo {
    width: 100%;
    max-width: 35rem;
    border-radius: 2rem;
    background-color: v.$rojo;
    padding-top: 2rem;
}
.infusion-form .row {
    display: block;
    padding: 0rem 1.5rem;
    input,select {
        padding: 1rem .5rem;
        width: 100%;
    }
    .m6 {
        margin: 2rem 0px;
    }
    .m12 {
        width: 100%;
    }
    @include m.tablet{
        display:flex;
        gap: 2rem;
    }
}
.form_rojo_div_boton_enviar {
    background-color: v.$azul;
    padding: 1.5rem 2rem;
    margin-top: 1rem;
    text-align: center;
    color: v.$blanco;
}
.form_check_texto {
    color: v.$blanco;
    font-size: 1.4rem;
    padding: 2rem 0;
}
.form_rojo_div_boton_check {
   
}
.caracteristicas {
    background-color: v.$azul;
    height: 5.5rem;
    line-height: 5.5rem;
    margin-bottom: -1rem;
    @include m.tablet{
        margin-bottom: -0.1rem;
    }

    h2 {
        color: v.$blanco;
        font-size: 2.5rem;
        font-weight: 100;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 0;
    }
}
.caracteristicas_3 {
    background-color: v.$azul;
    padding: 3rem;
    .caracteristica {
        img {
            width: 6rem;
            margin: 0 auto;
            display: block;
        }
        h3 {
            margin: 0;
            padding: 0;
            text-align: center;
            color: v.$blanco;
            font-weight: 100;
            font-size: 2.2rem;
            margin-top: 0.5rem;
            font-family: v.$fuente_principal;
        }
        p {
            color: v.$blanco;
            font-size: 1.5rem;
            line-height: 1.8;
        }
        @include m.tablet{
            @include m.grid(3,5rem);
        }
        .circulo {
            border-radius: 50%;
            width: 100%;
            max-width: 20rem;
        }
    }
    
}
.gris {
    background-color: v.$gris;
    padding: 5rem 0;
}
.audio_div {
    display: flex;
    width: 100%;
    max-width: 40rem;
    margin: 3rem auto;
    @include m.tablet{
        margin: 0 auto;
    }
    .audio_play {
        width: 6.7rem;
        height: 6.7rem;
        background-color: v.$rojo;
        img {
            width: 3rem;
            margin: 0 auto;
            padding-top: 1.8rem;
        }
    }

    .audio_negro {
        width: 100%;
        height: 6.7rem;
        background-color: v.$negro;
        color: v.$blanco;
        padding: .5rem 0.5rem 0 1rem;
        position: relative;
       .audio_autor {
            font-size: 1.2rem;
            font-weight: 100;
            font-style: italic;
       }
    }
}
.demos {
    padding-top: 3rem 0;
    @include m.tablet{
        @include m.grid(2,5rem);
    }

}
.audio_h2 {
    text-align: center;
    padding-top: 5rem;
    font-size: 2.4rem;
}
.boton_audio {
    cursor: pointer;
}
.audio_div .activo {
    background-color: v.$grisMedio;
}
.progresion {
    background-color: v.$rojo;
    width: 0;
    height: 1rem;
    margin: .5rem 0;
}
.audio_descarga {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: v.$negro;
}
.form_rojo_div_boton_enviar, #form_rojo_div_boton_check_img {
    cursor: pointer;
}
#mensajeError p {
    color: v.$blanco;
    font-size: 1.4rem;
}
.div_fondo {
    width: 100%;
    height: 40rem;
    background-color: v.$azul_gris;
    background-image: url(../img/fondo-barra-c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include m.tablet{
        background-image: url(../img/fondo-barra.jpg);
    }

}
.div_fondo_barra {
    width: 100%;
    height: 4rem;
    background-color: v.$azul_oscuro;
}
body .locutor {
    background-color: v.$azul_oscuro;
}

/*
.prueba {
    @include m.heading
}
.texto {
    @include m.heading(v.$amarillo);
    font-size: 1rem;
}
*/