@use 'base/variables' as v;
@use 'base/mixins' as m;

.form {
    width: 100%;
    max-width: 360px;
    padding: 4rem 2rem;
    margin: 15px auto;
    display: block;
    background-color: v.$negro;
    border-radius: 15px;
    .boton-form {
        cursor: pointer;
        font-size: 1.4rem;
        background-color: v.$rojo;
        padding: 0px 10px;
        width: 100%;
        max-width: 300px;
        margin: 0px auto;
        border: none;
        height: 45px;
        display: block;
        color: v.$blanco;
        font-weight: bold;
        &:hover{
            color: v.$rojo;
            background-color: v.$blanco;
        }
    }
}
.input-field input,.input-field select {
    border: none;
    margin-bottom: 2.5rem;
    padding: 1.2rem 0.8rem;
    width: 100%;
}
.movimiento {
    animation: rocking 2s infinite;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    transition: .2s;
}
@keyframes rocking{0%{transform:rotate(0deg)}25%{transform:rotate(0deg)}50%{transform:rotate(2deg)}75%{transform:rotate(-2deg)}100%{transform:rotate(0deg)}}
		.movimiento {
    animation: rocking 2s infinite;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    transition: .2s;
}
.div_reloj {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 20px auto;
}
.horario {
    width: 100%;
    max-width: 411px;
    display: block;
    margin: 0 auto;
}
.vacio {
    background-color: v.$amarillo;
}
.mensajeError p {
    color: v.$blanco;
    text-align: center;
    font-size: 1.7rem;
}