@use 'base/variables' as v;
@use 'base/mixins' as m;

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
  }
  .img_video {
    width: 100%;
    max-width: 50rem;

  }
  .caja_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  .boton-cerrar {
    background-color: v.$blanco;
    display: flex;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
  .cerrar {
    padding: 0;
    margin: 0;
    text-align: center;
    color: v.$blanco;
    font-size: 1.5rem;
    padding-top: 0.5rem;
  }