@use 'base/variables' as v;
.gracias h3 {
    font-weight: bold;
}
.gracias h2  {
    font-weight: bold;
    font-size: 3rem;
    color: v.$rojo;
    margin: 2rem auto 0 auto;
}
.boton {
    background-color: #000;
    border-radius: 1.5rem;
    color: #fff;
    display: inline-block;
    font-size: 2.3rem;
    margin-bottom: 2rem;
    padding: 1.8rem;
}