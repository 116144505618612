// Tipografia
$fuente_principal:'Montserrat', sans-serif;
$fuente_arial: Arial, Helvetica, sans-serif;

$rojo: #E30808;
$rojoOscuro: #9d0e00;
$verde:#00b9c4;
$rosa:#f53756;
$amarillo:#fef46d;
$morado:#752f97;
$negro:#000;
$blanco:#fff;
$gris_claro : #f0f0f0;
$gris: #b2b2b2;
$grisMedio:#626262;
$grisOscuro:#343434;
$inicio: #dbe6e9;
$azul_claro: #88a4bd;
$azul: #003652;
$azul_gris: #314e65;
$azul_oscuro: #1f3241;
$telefono: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;
