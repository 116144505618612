@use 'base/variables' as v;
@use 'base/mixins' as m;

.anfitriones {
    padding: 5rem 0;;
    background-color: v.$blanco;
    h2{
        text-align: center;
        color: v.$negro;
        font-weight: 700;
        margin-bottom: 0;
    }
    img {
        max-width: 28rem;
        display: block;
        margin: 0 auto;
    }
    .anfitriones_contenido {
        width: 100%;
        max-width: 80rem;
        margin: 0 auto;
        padding-top: 2rem;
        @include m.tablet{
            padding-top: 0;
        }
        h3{
            font-weight: 400;
            margin-bottom: 1rem;
            @include m.tablet{
                margin-bottom: 0;
            }
        }
    }
}
.anfitrion-contenedor {
    @include m.tablet{
        @include m.grid(2,5rem);
    }
}